import { __assign } from 'tslib';
import { drag } from '../../motion/features/drag.js';
import { layoutAnimations } from '../../motion/features/layout/index.js';
import { domAnimation } from './features-animation.js';

/**
 * @public
 */
var domMax = __assign(__assign(__assign({}, domAnimation), drag), layoutAnimations);

export { domMax };
